import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./expi-app/locales/en/translation.json";
import esTranslation from "./expi-app/locales/es/translation.json";
import ptTranslation from "./expi-app/locales/pt/translation.json";
import srTranslation from "./expi-app/locales/sr/translation.json";
import plTranslation from "./expi-app/locales/pl/translation.json";
import baTranslation from "./expi-app/locales/ba/translation.json";
import baFashionTranslation from "./fashion-expi-app/locales/ba/translation.json";
import enFashionTranslation from "./fashion-expi-app/locales/en/translation.json";
import esFashionTranslation from "./fashion-expi-app/locales/es/translation.json";
import ptFashionTranslation from "./fashion-expi-app/locales/pt/translation.json";
import plFashionTranslation from "./fashion-expi-app/locales/pl/translation.json";
import srFashionTranslation from "./fashion-expi-app/locales/sr/translation.json";
import { Tenants } from "./expi-app/api/authApi";

const getDefaultLanguage = () => {
  const host = window.location.host;
  const language = localStorage.getItem('language');


  if (host.includes(Tenants.DoziviSrpsku)) return 'sr';
  if (host.includes(Tenants.BosnianOutdoor)) return 'ba';
  if (language) return language;
  else return 'en';
}

const isFashionApp = () => {
  const host = window.location.host;
  const isFashionApp = host.includes("fashion") || host.includes("brunner")
  return isFashionApp
}

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: getDefaultLanguage() || 'en',
  debug: true,

  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: isFashionApp() ? enFashionTranslation : enTranslation,
    },
    es: {
      translation: isFashionApp() ? esFashionTranslation : esTranslation,
    },
    pt: {
      translation: isFashionApp() ? ptFashionTranslation : ptTranslation,
    },
    sr: {
      translation: isFashionApp() ? srFashionTranslation : srTranslation,
    },
    pl: {
      translation: isFashionApp() ? plFashionTranslation : plTranslation,
    },
    ba: {
      translation: isFashionApp() ? baFashionTranslation : baTranslation,
    }
  },
});

export default i18n;
