import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Suspense, lazy } from "react";
const FashionExpiApp = lazy(() => import("./fashion-expi-app/FashionExpiApp"));
const ExpiApp = lazy(() => import("./expi-app/ExpiApp"));

function App() {
  const host = window.location.host.toLowerCase();
  const isFashion = host.includes("fashion") || host.includes("brunner") || host.includes("elastra");

  return (
    <BrowserRouter>
      <Routes>
        {isFashion ? (
          <>
            <Route
              path="*"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <FashionExpiApp />
                </Suspense>
              }
            />
          </>
        ) : (
          <Route
            path="*"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <ExpiApp />
              </Suspense>
            }
          />
        )}
      </Routes>
      <Toaster position="top-center" reverseOrder={true} />
    </BrowserRouter>
  );
}

export default App;
