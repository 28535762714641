import React, { createContext, useContext, useEffect, useState } from "react";
import { scrollToTop } from "../components/functions/scrollUtils";
import mixpanel from "mixpanel-browser";
import { ExpiData } from "../api/types";

export type InitialFilterType = {
  brand: string;
  collection: string;
};

interface AppContextType {
  isSidebarVisible: boolean;
  isProfileModalVisible: boolean;
  username: string | null;
  showAdditionalInfoSidebar: boolean;
  eventDetails: EventDetails;
  isBidder: boolean;
  showAddProductModal: boolean;
  showUploadImageModal: boolean;
  toggleUploadImageModal: (status?: boolean) => void;
  toggleAdditionalInfoSidebar: (status?: boolean) => void;
  toggleSidebar: () => void;
  toggleAddProductModal: () => void;
  toggleProfileModal: (status?: boolean) => void;
  setUser: (
    name?: string,
    avatar?: string,
    isBidder?: boolean,
    isTenant?: boolean,
    token?: string,
    rebate?: number
  ) => void;
  handleSelectedEvent: (event: ExpiData) => void;
  toggleEventDetails: (state?: boolean) => void;
  warehouseProducts: ExpiData[];
  setWarehouseProducts: React.Dispatch<React.SetStateAction<ExpiData[]>>;
  productDetails: ExpiData | null;
  setProductDetails: (event: ExpiData | null) => void;
  initialFilter: InitialFilterType;
  setInitialFilter: (filter: InitialFilterType) => void;
  updateInitialFilter: (updates: Partial<InitialFilterType>) => void;
  rebate: number;
  avatarImg: string;
  setAvatarImg: (value: string) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const useFashionAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useFashionAppContext must be used within an AppProvider");
  }
  return context;
};

interface AppProviderProps {
  children: React.ReactNode;
}

type EventDetails = {
  selectedEvent: ExpiData | null;
  isShowed: boolean;
};

export const FashionAppProvider = ({ children }: AppProviderProps) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [isBidder, setIsBidder] = useState(false);
  const [avatarImg, setAvatarImg] = useState("");
  const [showAdditionalInfoSidebar, setShowAdditionalInfoSidebar] =
    useState<boolean>(false);
  const [eventDetails, setEventDetails] = useState<EventDetails>({
    selectedEvent: null,
    isShowed: false,
  });
  const [warehouseProducts, setWarehouseProducts] = useState<ExpiData[]>([]);
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);
  const [productDetails, setProductDetails] = useState<ExpiData | null>(null);
  const [initialFilter, setInitialFilter] = useState<InitialFilterType>({
    brand: "",
    collection: "",
  });
  const [rebate, setRebate] = useState<number>(0);

  useEffect(() => {
    const storedIsBidder = localStorage.getItem("isBidder");
    const storedUsername = localStorage.getItem("username");
    const storedRebate = localStorage.getItem("rebate");
    const storedAvatar = localStorage.getItem("user-image");
    if (storedIsBidder) {
      setIsBidder(JSON.parse(storedIsBidder));
    }
    if (storedUsername) {
      setUsername(storedUsername);
    }
    if (storedRebate) {
      setRebate(parseFloat(storedRebate));
    }
    if (storedAvatar) {
      setAvatarImg(storedAvatar);
    }
  }, []);

  const toggleUploadImageModal = (state: boolean = !showUploadImageModal) => {
    setShowUploadImageModal(state);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const toggleAddProductModal = () => {
    setShowAddProductModal(!showAddProductModal);
  };

  const toggleAdditionalInfoSidebar = (
    state: boolean = !showAdditionalInfoSidebar
  ) => {
    setShowAdditionalInfoSidebar(state);
    mixpanel.track(
      "Additional options side bar: Show/Hide additional options",
      { state }
    );
  };

  const toggleProfileModal = (state: boolean = !isProfileModalVisible) => {
    setIsProfileModalVisible(state);
  };

  const setUser = (
    name: string = username,
    avatar: string = avatarImg,
    isBidder?: boolean,
    isTenant: boolean = false,
    token?: string,
    rebate: number = 0
  ) => {
    localStorage.setItem("isTenant", JSON.stringify(isTenant));
    localStorage.setItem("username", name);
    localStorage.setItem("user-image", avatar);
    localStorage.setItem("rebate", JSON.stringify(rebate));
    token && localStorage.setItem("token", token);
    localStorage.setItem("isBidder", JSON.stringify(isBidder));
    setIsBidder(isBidder ?? false);
    setUsername(name);
    setAvatarImg(avatar);
    setRebate(rebate);
  };

  const handleSelectedEvent = (event: ExpiData) => {
    setEventDetails({ isShowed: true, selectedEvent: event });
    mixpanel.track("Show event details clicked");
    scrollToTop();
  };

  const toggleEventDetails = (state?: boolean) => {
    if (typeof state !== "undefined") {
      setEventDetails((prev) => ({ ...prev, isShowed: state }));
    } else {
      setEventDetails((prev) => ({
        ...prev,
        isShowed: !eventDetails.isShowed,
      }));
    }
  };

  const updateInitialFilter = (updates: Partial<InitialFilterType>) => {
    setInitialFilter((prev) => ({
      ...prev,
      ...updates,
    }));
  };

  const contextValue: AppContextType = {
    isSidebarVisible,
    showAddProductModal,
    isProfileModalVisible,
    username,
    showAdditionalInfoSidebar,
    eventDetails,
    isBidder,
    showUploadImageModal,
    productDetails,
    setProductDetails,
    toggleUploadImageModal,
    toggleSidebar,
    toggleProfileModal,
    setUser,
    toggleAdditionalInfoSidebar,
    handleSelectedEvent,
    toggleEventDetails,
    toggleAddProductModal,
    warehouseProducts,
    setWarehouseProducts,
    setInitialFilter,
    initialFilter,
    updateInitialFilter,
    rebate,
    avatarImg,
    setAvatarImg,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
